import Link from 'next/link';
import React from 'react';

export const findErrorMessageFromErrorCode = (
  errorCode: string | string[] | undefined,
): string | JSX.Element | null => {
  switch (errorCode) {
    case 'user_not_found':
      return (
        <>
          There is no existing WorkOS account for this email address. Try again
          with another email address or{' '}
          <Link href="/signup">
            <span className="cursor-pointer underline">
              register for a new account
            </span>
          </Link>
          .
        </>
      );

    case 'team_not_found':
      return (
        <>
          We encountered an issue signing in with this account. Please try
          again. If the problem persists,{' '}
          <a
            className="cursor-pointer underline"
            href="mailto:support@workos.com"
          >
            contact support
          </a>
          .
        </>
      );
    default:
      return null;
  }
};
