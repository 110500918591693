import { Alert, Button, Text } from '@workos-inc/component-library';
import axios from 'axios';
import { TermsAndPrivacyNotice } from 'components/auth/terms-and-privacy-notice';
import { Card } from 'components/card';
import { InputGroup } from 'components/form/input-group';
import { GoogleLogo } from 'components/google-logo';
import { Link } from 'components/link';
import { Logo } from 'components/logo';
import { withPage } from 'components/with-page';
import isEmail from 'is-email';
import { useRouter } from 'next/router';
import querystring from 'query-string';
import React, { FC, FormEvent, useEffect, useState } from 'react';
import { findErrorMessageFromErrorCode } from 'utils/login/errors';
import { isAxiosError } from '../utils/axios';

export const Login: FC<Readonly<unknown>> = () => {
  const router = useRouter();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | string[] | JSX.Element | null>(
    '',
  );
  const [userNotConfirmed, setUserNotConfirmedError] = useState(false);
  const [message, setMessage] = useState(router.query.message || '');
  const [loginType, setLoginType] = useState('');
  const [formError, setFormError] = useState({ email: '' });
  const [isGoogleRequesting, setAsGoogleRequesting] = useState(false);
  const [isLoginRequesting, setAsLoginRequesting] = useState(false);

  const emailQuery = `?email=${email}`;
  const resetPasswordURL = `/auth/reset-password${email ? emailQuery : ''}`;
  const redirect = router.query.redirect || '/';

  useEffect(() => {
    const errorCode = router.query.error_code;
    const errorMessage = findErrorMessageFromErrorCode(errorCode);

    if (errorMessage) {
      return setError(errorMessage);
    }

    // LEGACY: Set up an error code in favor of using error_description.
    if (router.query.error_description) {
      return setError(router.query.error_description);
    }
  }, [router.query.error_description, router.query.error_code]);

  useEffect(() => {
    if (router.query.message) {
      return setMessage(router.query.message);
    }
  }, [router.query.message]);

  const initiateGoogleLogin = () => {
    setAsGoogleRequesting(true);
    const query = querystring.stringify({
      client_id: process.env.NEXT_PUBLIC_PROJECT_ID,
      provider: 'GoogleOAuth',
      redirect_uri: `${process.env.NEXT_PUBLIC_API_URL}/auth/google/callback`,
      state: btoa(JSON.stringify({ redirect, location: 'unknown' })),
      response_type: 'code',
    });

    window.location.href = `${process.env.NEXT_PUBLIC_API_URL}/sso/authorize?${query}`;
  };

  const resetAlerts = () => {
    setUserNotConfirmedError(false);
    setMessage('');
    setError('');
  };

  const handleLoginWithPassword = async () => {
    try {
      setAsLoginRequesting(true);
      resetAlerts();

      const { data } = await axios.post(
        `${process.env.NEXT_PUBLIC_API_URL}/auth/email`,
        { email, password, redirect },
        { withCredentials: true },
      );

      const redirectTo: string | undefined = data.redirectTo;

      if (!redirectTo) {
        return await router.push('/');
      }

      window.location.href = redirectTo;
    } catch (error) {
      if (
        isAxiosError(error) &&
        error.response?.data?.message === 'UserNotConfirmed'
      ) {
        setUserNotConfirmedError(true);
      } else {
        setError('Invalid Email or Password.');
      }
    } finally {
      setAsLoginRequesting(false);
    }
  };

  const resendConfirmationEmail = async () => {
    try {
      setAsLoginRequesting(true);
      resetAlerts();

      await axios.post(
        `${process.env.NEXT_PUBLIC_API_URL}/v2/registrations/resend-confirmation-email`,
        { email },
      );

      void router.push('/auth/confirm-email');
    } catch (error) {
      setError('Error resending your confirmation email, please try again.');
    } finally {
      setAsLoginRequesting(false);
    }
  };

  const handleLogin = async (event: FormEvent) => {
    event.preventDefault();

    if (!isEmail(email)) {
      return setFormError({ email: 'Email is invalid' });
    }

    setFormError({ email: '' });

    if (email && password) {
      return handleLoginWithPassword();
    }

    try {
      const { data } = await axios(
        `${process.env.NEXT_PUBLIC_API_URL}/auth/provider`,
        {
          params: { email },
        },
      );

      if (data.provider === 'Email') {
        return setLoginType('password');
      }

      if (data.provider === 'Google') {
        return initiateGoogleLogin();
      }
    } catch (error) {
      return setLoginType('password');
    }
  };

  return (
    <div
      className="max-w-md mx-auto pt-20 pb-8 text-center flex justify-between flex-col min-h-screen"
      data-testid="new-login"
    >
      <div>
        <Card>
          <Card.Body className="!p-8">
            <Logo className="mx-auto" size="50px" wordmark={false} />
            <Text as="h1" className="mb-6 mt-4" size="large" weight="medium">
              Sign in to WorkOS
            </Text>

            {userNotConfirmed && (
              <Alert appearance="red" className="mb-5">
                Your account has not been confirmed. Please check your email. If
                you'd like to resend the email please{' '}
                <span
                  className="underline cursor-pointer"
                  onClick={resendConfirmationEmail}
                >
                  click here
                </span>
                .
              </Alert>
            )}

            {error && (
              <Alert appearance="red" className="mb-5">
                {error}
              </Alert>
            )}
            {message && (
              <Alert appearance="green" className="mb-5">
                {message}
              </Alert>
            )}

            <form onSubmit={handleLogin}>
              <InputGroup
                className="mb-4 text-left"
                error={formError.email}
                id="email"
                label="Email"
                name="email"
                onChange={(event) => setEmail(event.target.value)}
                placeholder="Enter your email"
                size="large"
                type="email"
                value={email}
              />

              {loginType === 'password' && (
                <div className="relative">
                  <InputGroup
                    className="mb-4 text-left"
                    id="password"
                    label="Password"
                    name="password"
                    onChange={(event) => setPassword(event.target.value)}
                    placeholder="Enter your password"
                    size="large"
                    type="password"
                    value={password}
                  />

                  <Text
                    as="p"
                    className="absolute right-0 top-0"
                    weight="medium"
                  >
                    <Link
                      appearance="highlight"
                      as="/auth/reset-password"
                      href={resetPasswordURL}
                    >
                      Forgot your password?
                    </Link>
                  </Text>
                </div>
              )}

              <Button
                appearance="primary"
                className="w-full justify-center"
                isLoading={isLoginRequesting}
                size="large"
                type="submit"
              >
                Continue
              </Button>
            </form>

            <div className="relative mt-6 mb-6">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-lightmode-200" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-lightmode-300 uppercase text-xs font-medium">
                  Or
                </span>
              </div>
            </div>

            <Button
              appearance="secondary"
              className="justify-center w-full"
              iconLeft={<GoogleLogo />}
              isLoading={isGoogleRequesting}
              onClick={initiateGoogleLogin}
              size="large"
            >
              Sign in with Google
            </Button>

            <TermsAndPrivacyNotice className="mt-6" />
          </Card.Body>
        </Card>

        <Text as="p" className="mt-8">
          Don't have an account?{' '}
          <Link appearance="highlight" href="/signup">
            Sign up
          </Link>
        </Text>
      </div>
    </div>
  );
};

export default withPage(Login, {
  isProtected: false,
  metaTitle: 'Login',
  metaCanonical: 'login',
  theme: 'light',
});
