import { Text } from '@workos-inc/component-library';
import classnames from 'classnames';
import { Link } from 'components/link';
import React, { FC } from 'react';

export interface TermsAndPrivacyNoticeProps {
  className?: string;
}

export const TermsAndPrivacyNotice: FC<
  Readonly<TermsAndPrivacyNoticeProps>
> = ({ className }) => (
  <Text multiline as="p" className={classnames('text-center', className)}>
    By signing in, you agree to our <br />
    <Link appearance="highlight" href="https://workos.com/terms">
      Terms of Service
    </Link>{' '}
    and{' '}
    <Link appearance="highlight" href="https://workos.com/privacy">
      Privacy Policy
    </Link>
  </Text>
);
